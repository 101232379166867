<template>
  <div
    class="box"
  >
    <van-button style="width: 100%;margin-top: 100px;;" icon="scan" type="info" @click="preview"
      >点击扫码</van-button
    >
    <div style="margin-top: 30px;;">扫码结果：</div>
    <div style="margin-top: 10px;;width:94%;margin-left:3%;word-break: break-all;font-size:12px;">{{ barcodeInfo }}</div>
  </div>
</template>
<script>
import { Button } from "vant";
import { passenger } from "@/api";
const wx = window.wx;
export default {
  components: {
    VanButton: Button,
  },
  data() {
    return {
      barcodeInfo: "",
    };
  },
  mounted() {
    document.title = "扫码";
    this.getwxSign();
  },
  methods: {
    preview() {
      let that = this;
      //(01)06950567921666(17)230507(10)P07202302008(91)999
      // let str = "CODE_128,01069505679216661723050710P0720230200891999";
      // let strlist = str.split(",");
      // let code = strlist[1];
      // that.barcodeInfo = `(${code.substring(0,2)})${code.substring(2,16)}(${code.substring(16,18)})${code.substring(18,24)}(${code.substring(24,26)})${code.substring(26,38)}(${code.substring(38,40)})${code.substring(40,43)}`
      // console.log(lscode)
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["barCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (res) {
          var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
          let strlist = result.split(",");
          let code = strlist[1];
          // let code = res.resultStr;
          that.barcodeInfo = `(${code.substring(0,2)})${code.substring(2,16)}(${code.substring(16,18)})${code.substring(18,24)}(${code.substring(24,26)})${code.substring(26,38)}(${code.substring(38,40)})${code.substring(40,43)}`;
        },
        error: function(res) {
            if (res.errMsg.indexOf('function_not_exist') > 0) {
                alert('版本过低请升级')
            }
        }
      });
    },
    getwxSign() {
      let params = {
        url: document.location.href.split("#")[0],
      };
      passenger.getWxSigns(params).then((res) => {
        if (res.data.code == 200) {
          wx.config({
            //beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: "wxf1e471795a1fd4e4", // 自己的：wxf1e471795a1fd4e4，sih: wxa77ba17b66c5cad5
            timestamp: res.data.data.timestamp, //
            nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.data.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
            jsApiList: [
              "scanQRCode",
              "onMenuShareTimeline",
              "onMenuShareAppMessage",
              "updateAppMessageShareData", //1.4 分享到朋友
              "updateTimelineShareData", //1.4分享到朋友圈
              "onMenuShareQQ",
              "onMenuShareWeibo",
              "onMenuShareQZone",
              "hideMenuItems",
              "showMenuItems",
            ], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
          });
          wx.ready(function () {
            wx.hideMenuItems({
              menuList: [
                "menuItem:share:appMessage",
                "menuItem:share:timeline",
                "menuItem:share:qq",
                "menuItem:share:weiboApp",
                "menuItem:share:appMessage",
                "menuItem:copyUrl",
                "menuItem:openWithQQBrowser",
                "menuItem:openWithSafari",
                "menuItem:share:brand",
                "menuItem:share:QZone",
                "menuItem:share:facebook",
                "menuItem:favorite",
              ], // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有 menu 项见附录3
            });
            // alert("ready");
            // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
          });
          wx.error(function (res) {
            this.tsRemark = JSON.stringify(res);
            alert(JSON.stringify(res), "error");
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
          });
        }
      });
    },
    getQywxSign() {
      let params = {
        url: document.location.href.split("#")[0],
      };
      passenger.getQyWxSigns(params).then((res) => {
        if (res.data.code == 200) {
          wx.config({
            //beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
            debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: "wxa77ba17b66c5cad5", // 自己的：wxf1e471795a1fd4e4，sih: wxa77ba17b66c5cad5
            timestamp: res.data.data.timestamp, //
            nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.data.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
            jsApiList: [
              "scanQRCode",
              "onMenuShareTimeline",
              "onMenuShareAppMessage",
              "updateAppMessageShareData", //1.4 分享到朋友
              "updateTimelineShareData", //1.4分享到朋友圈
              "onMenuShareQQ",
              "onMenuShareWeibo",
              "onMenuShareQZone",
              "hideMenuItems",
              "showMenuItems",
            ], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
          });
          wx.ready(function () {
            wx.hideMenuItems({
              menuList: [
                "menuItem:share:appMessage",
                "menuItem:share:timeline",
                "menuItem:share:qq",
                "menuItem:share:weiboApp",
                "menuItem:share:appMessage",
                "menuItem:copyUrl",
                "menuItem:openWithQQBrowser",
                "menuItem:openWithSafari",
                "menuItem:share:brand",
                "menuItem:share:QZone",
                "menuItem:share:facebook",
                "menuItem:favorite",
                "menuItem:favorite",
              ], // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有 menu 项见附录3
            });
            // alert("ready");
            // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
          });
          wx.error(function (res) {
            this.tsRemark = JSON.stringify(res);
            alert(JSON.stringify(res), "error");
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.box {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
